import { ListItemButton, ListItemIcon } from '@mui/material';
import { styled } from '@mui/material/styles'; 
import { NavLink } from 'react-router-dom';


const LinkItemStyled = styled(NavLink)(({ isActive, open }) => ({
    textDecoration: 'none',
    display: 'flex', 
    alignItems: 'center',
    fontSize: open ? '0.1rem' : '1rem',
    color: isActive ? '#9E9E9E' : '#9E9E9E',
    backgroundColor: isActive ? '#FFD700' : 'transparent',
    borderTopRightRadius: isActive ? '25px' : null,
    borderBottomRightRadius: isActive ? '25px' : null,
    width: open ? '14.5rem' : '3rem',
    paddingLeft: '0.1rem', 
    '& span': {
        fontSize: open ? '1.1rem' : '1.2rem', 
        marginLeft: '-1.4rem'
    }
}));

const ItemIconStyled = styled(ListItemIcon)(({ open }) => ({
  minWidth: 0,
  color: '#9E9E9E',
  marginRight: open ? 24 : 'auto',
  justifyContent: 'center',
  '& svg': {
    fontSize: open ? '1.5rem' : '1.5rem', 
  },
}));

const ItemButtonStyled = styled(ListItemButton)(({ open }) => ({
  minHeight: 48,
  justifyContent: open ? 'initial' : 'center',
  paddingLeft: 16, 
  paddingRight: 16,
}));


export { ItemButtonStyled, ItemIconStyled, LinkItemStyled };
