import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TopBar from '../../components/TopBar';

const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <div className="flex flex-col min-h-screen">
      {!location.pathname.startsWith('/admin') && <TopBar />}
      {!location.pathname.startsWith('/admin') && <Header />}
      <main className="flex-grow">
        {children}
      </main>
      {!location.pathname.startsWith('/admin') && <Footer />}
    </div>
  );
};

export default Layout;
