import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import LandCard from '../pages/Land/components/LandCard';
import LeaseCard from '../pages/Lease/components/LeaseCard';
import HouseForSaleCard from '../pages/HouseForSale/components/HouseForSaleCard';
import ApartmentCard from '../pages/Apartments/components/ApartmentCard';
import Filter from './Filter';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router';

const PrevArrow = ({ onClick }) => (
  <button
    className="absolute left-0 top-1/2 transform -translate-y-1/2 p-6 rounded-full z-10 hover:bg-gray-200 transition-colors lg:-ml-2 -ml-6"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faAngleLeft} className="text-gray-700" size="lg" />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button
    className="absolute right-0 top-1/2 transform -translate-y-1/2 p-6 rounded-full z-10 hover:bg-gray-200 transition-colors md:-mr-4 lg:-mr-4 xl:-mr-4 -mr-6"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faAngleRight} className="text-gray-700" size="lg" />
  </button>
);

const Carousel = ({ data, title, type, desc, route, selectedCategory, setSelectedCategory, setSortOrder }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1480,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1280, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '0px',
        },
      },
    ],
  };

  const navigate = useNavigate();
  return (
    <div className="relative p-4">
      <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row justify-between items-center md:justify-between md:items-center mt-4 -mx-2 md:mx-auto lg:mx-auto xl:mx-auto w-full md:w-[85.3%] lg:w-[85.3%] xl:w-[85.3%] text-center md:text-center lg:text-center xl:text-center">
        <div className="flex flex-col items-center md:items-start lg:items-start xl:items-start mb-4 md:mb-0 lg:mb-0 xl:mb-0 w-full md:w-auto lg:w-auto xl:w-auto md:flex-grow lg:flex-grow xl:flex-grow">
          <h2 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mb-2">{title}</h2>
          <div 
            className="text-gray-500 text-sm md:text-base text-center md:text-left md:mb-4 lg:text-lg xl:text-xl cursor-pointer hover:opacity-80 hover:text-yellow-800 hover:ml-4 transition-all duration-300 rounded-md shadow-md p-2"
            onClick={() => navigate(route)}
          >
            {desc}
          </div>
         </div>

      </div>

      <div className="flex justify-center items-center -mx-4 md:mx-20 lg:mx-20 xl:mx-20 md:overflow-hidden lg:overflow-hidden xl:overflow-hidden relative">
        <Slider {...settings} className="w-full pr-1.5 md:px-8 lg:px-8 xl:px-8">
          {data.map((item, index) => (
            <div key={index} className="px-4 md:px-4 lg:px-4 xl:px-4 mx-1 flex-shrink-0">
              {type === 'Land' && <LandCard data={item} />}
              {type === 'Rent' && <LeaseCard data={item} />}
              {type === 'Apartment' && <ApartmentCard data={item} />}
              {type === 'HouseForSale' && <HouseForSaleCard data={item} />}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
