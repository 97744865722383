

import Layout from '../Home/components/Layout.js';
import Home from '../Dashboard/Dashboard.js';
import Properties from './components/index.js';

function AdminProperties() {
  return (
            <Layout>
               <Properties />
            </Layout>
  );
}

export default AdminProperties;
