

import Layout from '../Home/components/Layout.js';
import PropertyOwner from './components/index.js';
function AdminPropertyOwner() {
  return (
            <Layout>
               <PropertyOwner />
            </Layout>
  );
}

export default AdminPropertyOwner;
