
export const getTruncatedAddress = (address, maxLength = 45) => {
    const fullAddress = [
      address?.region?.name,
      address?.district?.name,
      address?.ward?.name,
      address?.street?.name
    ]
    .filter(Boolean) 
    .join(', '); 
  
    if (fullAddress.length > maxLength) {
      return fullAddress.slice(0, maxLength) + '...';
    }
  
    return fullAddress;
  };