
import React, { useState } from 'react';
import Search from '../../components/Search';
import Carousel from '../../components/Carousel';
import Banner from './components/Banner';
import { useProperties } from '../../context/propertiesContext';
import PropertyList from './PropertyList';
import { getAddressString } from '../../utils/getAddressString';

const Home = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [sortOrder, setSortOrder] = useState('new');
  const [typeFilter, setTypeFilter] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const { categories, properties } = useProperties();

  const handleFilterType = (type) => {
    setTypeFilter(type);
  };

  const handleFilterPrice = (min, max) => {
    setMinPrice(min);
    setMaxPrice(max);
  };

  const getPropertyPrice = (property) => {
    switch (property.type) {
      case 'Rent':
        return property.leasePrice;
      case 'Land':
        return property.lotPrice;
      case 'Apartment':
        return property.appartmentPrice;
      case 'HouseForSale':
        return property.housePrice;
      default:
        return 0; 
    }
  };

  let filteredProperties = [];
  filteredProperties = properties
    .filter((property) => {
      const matchesSearchTerm = 
      (property.title && typeof property.title === 'string' && property.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (property.propertyAddress && typeof property.propertyAddress === 'object' && getAddressString(property.propertyAddress).toLowerCase().includes(searchTerm.toLowerCase()));
    
      const matchesTypeFilter = typeFilter
        ? property.type === typeFilter
        : true;

      const matchesPriceFilter = 
        (!minPrice || getPropertyPrice(property) >= Number(minPrice)) &&
        (!maxPrice || getPropertyPrice(property) <= Number(maxPrice));
      
      return matchesSearchTerm && matchesTypeFilter && matchesPriceFilter;
    });

  const isFilterActive = searchTerm || typeFilter || minPrice || maxPrice;

  return (
    <div className='bg-gray-100'>
      <main className="p-4">
        <Search
          placeholder="Search Properties in Tanzania"
          onSearch={setSearchTerm}
          onFilterType={handleFilterType}
          onFilterPrice={handleFilterPrice}
        />
        <Banner />

        {isFilterActive ? (
          <PropertyList properties={filteredProperties} />
        ) : (
          <div>
            <div className='mb-6'>
              <Carousel
                data={categories?.Rent}
                type="Rent"
                route='/lease'
                title="Nyumba za Kupanga"
                desc="Nyumba za kupanga 200+ zinapatikana"
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setSortOrder={setSortOrder}
              />
            </div>
            <div className='mb-6'>
              <Carousel
                data={categories?.Apartment}
                title="Appartments"
                type="Apartment"
                route='/apartment'
                desc="Nyumba 300+ zinapatikana"
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setSortOrder={setSortOrder}
              />
            </div>
            <div className='mb-6'>
              <Carousel
                data={categories?.Land}
                title="Viwanja"
                type="Land"
                route='/land'
                desc="Viwanja 500+ vinapatikana"
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setSortOrder={setSortOrder}
              />
            </div>
            <div className='mb-6'>
              <Carousel
                data={categories?.HouseForSale}
                title="Nyumba za Kuuza"
                type="HouseForSale"
                route='/house-for-sale'
                desc="Nyumba 500+ zinapatikana"
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setSortOrder={setSortOrder}
              />
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Home;
