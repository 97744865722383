import apiClient from "../api/apiClient";

export const getAllPromotionPlans = async () => {
    try {
      const response = await apiClient.get('/promotion-plan');
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription plans:', error);
      throw error; 
    }
  };
  



 export const createPromotion = async (promotionData) => {
  try {
    const { propertyId, planId } = promotionData;
    const response = await apiClient.post(`/properties/${propertyId}/promote`, {
        promotionPlanId : planId
    });

    return response.data; 
  } catch (error) {
    console.error("Error creating subscription:", error);
    throw error;
  }
};

