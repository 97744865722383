// UserTable.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography
} from '@mui/material';

const UserTable = () => {
  const navigate = useNavigate();

  const users = [
    { id: 1, name: 'John Doe', numOfProperties: 4, phoneNumber: '123-456-7890' },
    { id: 2, name: 'Jane Smith', numOfProperties: 2, phoneNumber: '098-765-4321' },
    // Add more user data here
  ];

  const handleRowClick = (userId) => {
    navigate(`/admin/users/${userId}`);
  };

  return (
    <div style={{marginTop: '56px'}}>
      <Typography variant="h4" gutterBottom>Users List</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Number of Properties</TableCell>
              <TableCell>Phone Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
              key={user.id}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: '#E6C767', 
                },
              }}
              onClick={() => handleRowClick(user.id)}
            >
            
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.numOfProperties}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserTable;
