import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, IconButton, Typography, Tabs, Tab, CircularProgress, Drawer } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import { useProperties } from '../../../../context/propertiesContext';
import { useNavigate } from 'react-router';


const Properties = () => {
  const [selectedTab, setSelectedTab] = useState('Land'); 
  const [properties, setProperties] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const  { getLand, getLease, getApartment, getHouseForSale } = useProperties();

  const navigate = useNavigate();

  const fetchProperties = async (tab) => {
    setLoading(true);
    setError(null); 
    try {
      let response;
      if (tab === 'Land') {
        response = await getLand();
      } else if (tab === 'Rent') {
        response = await getLease();
      } else if (tab === 'HouseForSale') {
        response = await getHouseForSale();
      } else if (tab === 'Apartment') {
        response = await getApartment();
      }
      setProperties(response); 
    } catch (err) {
      setError('Failed to load properties');
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchProperties(selectedTab);
  }, [selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewDetail = (property) => {
    if(property.type=='Land'){
      navigate(`/admin/land/${property.id}`, {state: {data:property}})
    } else if(property.type=='Rent'){
      navigate(`/admin/lease/${property.id}`, {state: {data:property}})
    } else if(property.type=='Apartment'){
      navigate(`/admin/apartment/${property.id}`, {state: {data:property}})
    }else if(property.type=='HouseForSale'){
      navigate(`/admin/house-for-sale/${property.id}`, {state: {data:property}})
    } else {
        return;
    }
  };



  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedProperty(null);
  };

  return (
    <div style={{marginTop: 70}}>
      <Typography variant="h4" gutterBottom>Properties Management</Typography>

      {/* Top Menu Tabs */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="Property Tabs"
        centered
        sx={{
          '& .MuiTab-root': {
            fontSize: '1rem',
            fontWeight: 600,
            padding: '12px 24px',
            marginTop: '.5rem',
            minWidth: '120px',
          },
          '& .MuiTab-iconWrapper': {
            fontSize: '1.5rem',
          },
        }}
      >
        <Tab label="Land" value="Land" />
        <Tab label="Lease" value="Rent" />
        <Tab label="House For Sale" value="HouseForSale" />
        <Tab label="Apartment" value="Apartment" />
      </Tabs>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        minHeight="200px" 
      >
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : properties.length > 0 ? (
          <TableContainer component={Paper} style={{ marginTop: 20 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Price (TZS)</TableCell>
                  {/* <TableCell>Is Active?</TableCell> */}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {properties.map((property) => (
                  <TableRow key={property.id}>
                    <TableCell>{property.id}</TableCell>
                    <TableCell>{property.title}</TableCell>
                    <TableCell>{property?.propertyAddress?.region?.name}</TableCell>
                    <TableCell>{property?.lotPrice}{property?.leasePrice}{property?.apartmentPrice}{property?.housePrice}</TableCell>
                    {/* <TableCell>{property?.isActive}</TableCell> */}
                    <TableCell>
                      <IconButton color="success" onClick={() => alert('Approve action')}>
                        <CheckCircleIcon />
                      </IconButton>
                      <IconButton color="primary" onClick={() => handleViewDetail(property)}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton color="error" onClick={() => alert('Disapprove action')}>
                        <CancelIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" style={{ marginTop: 20 }}>No properties available in this category.</Typography>
        )}
      </Box>

      {/* Side Panel Drawer for viewing details */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
        <div style={{ width: 300, padding: 20 }}>
          {selectedProperty && (
            <>
              <Typography variant="h6">Property Details</Typography>
              <Typography>ID: {selectedProperty.id}</Typography>
              <Typography>Name: {selectedProperty.name}</Typography>
              <Typography>Location: {selectedProperty.location}</Typography>
              <Typography>Price: {selectedProperty.price}</Typography>
              <Typography>Status: {selectedProperty.status}</Typography>
              <Button onClick={handleCloseDrawer} variant="contained" color="primary" fullWidth>
                Close
              </Button>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default Properties;
