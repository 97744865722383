import { useMediaQuery } from "@mui/material"; 
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Menu from "./Menu";

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);
  
  const mobile = useMediaQuery("(max-width:480px)");
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerCloseAuto = () => {
    if (mobile) {
      setOpen(false);
    }
  };

  const handleResponsiveDrawerClose = () => {
    if (mobile) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  let location = useLocation();

  useEffect(() => {
    handleResponsiveDrawerClose();
  }, [location]);

  return (
    <div className="flex">
      <Menu open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
      <div className={`flex-1 p-4 transition-all duration-300 ${open ? 'ml-10' : 'ml-4'}`} onClick={handleDrawerCloseAuto}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
