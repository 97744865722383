import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import * as React from "react";
import { AppBarStyled, LogoStyled, ToolbarStyled, FlexDiv } from "./TopMenuStyled";


const TopMenu = ({ handleDrawerOpen, open }) => {


  return (
    <AppBarStyled position="fixed" open={open}>
      <ToolbarStyled>
        <FlexDiv>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <LogoStyled variant="h6" noWrap component="div">
          <img src="/assets/images/logo.svg" alt="Logo" className="h-8" /> 
          </LogoStyled>
        </FlexDiv>
        <div>
          <ExitToAppIcon
            color="inherit"
            style={{ cursor: "pointer" }}
            onClick={()=>{}}
          ></ExitToAppIcon>
        </div>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default TopMenu;
