
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Container, Typography, Box, Alert } from "@mui/material";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === "" || password === "") {
      setError("Username and Password are required");
      return;
    }
    setError(""); 
    navigate("/admin/dashboard");
  };

  return (
    <Container
      maxWidth={false} 
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", 
        backgroundColor: "#898121", 
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: 6,
          borderRadius: 4,
          boxShadow: 3,
          width: { xs: "90%", sm: "400px", md: "500px" }, 
        }}
      >
        {/* Logo image */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 3,
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
            alt="Admin Logo"
            style={{ width: "150px", height: "auto" }}
          />
        </Box>

        <Typography
          variant="h3" 
          component="h1"
          textAlign="center"
          fontWeight="bold"
          sx={{ color: "black", marginBottom: 4 }}
        >
          Admin Login
        </Typography>

        {error && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleLogin}>
          <Box mb={3}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{ input: { backgroundColor: "white" }, borderRadius: 1 }}
            />
          </Box>

          <Box mb={3}>
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ input: { backgroundColor: "white" }, borderRadius: 1 }}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "black",
              color: "#fdd835",
              fontWeight: "bold",
              padding: 2, 
              fontSize: "1.1rem", 
              "&:hover": {
                backgroundColor: "#fdd835", 
                color: "black",
              },
            }}
          >
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default AdminLogin;
