import React from 'react';
import LandCard from '../Land/components/LandCard';
import LeaseCard from '../Lease/components/LeaseCard';
import HouseForSaleCard from '../HouseForSale/components/HouseForSaleCard';
import ApartmentCard from '../Apartments/components/ApartmentCard';

const PropertyList = ({ properties }) => {
  console.log("Properties");
  console.log(properties);
  const renderPropertyCard = (property) => {
    switch (property.type) {
      case 'Land':
        return <LandCard data={property} />;
      case 'Rent':
        return <LeaseCard data={property} />;
      case 'HouseForSale':
        return <HouseForSaleCard data={property} />;
      case 'Apartment':
        return <ApartmentCard data={property} />;
      default:
        return null;
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4 w-[86%] mx-auto justify-center items-center">
      {properties.map((property) => (
        <div key={property.id}>
          {renderPropertyCard(property)}
        </div>
      ))}
    </div>
  );
};

export default PropertyList;
