import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAllProperties, getPropertiesByPublisher, getByCategory } from '../utils/propertyFunc';

const PropertiesContext = createContext();

const PropertiesProvider = ({ children }) => {
  const [properties, setProperties] = useState([]);
  const [propertiesByPublisher, setPropertiesByPublisher] = useState([]);
  const [categories, setCategories] = useState({
    Land: [],
    Rent: [],
    Apartment: [],
    HouseForSale: []
  });
  const [loading, setLoading] = useState(true);

  const fetchAllProperties = async () => {
    try {
      setLoading(true);
      const data = await getAllProperties();
      console.log('All properties:', data);
      setProperties(data);
      categorizeProperties(data);
    } catch (error) {
      console.error('Error fetching all properties:', error);
      setProperties([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchPropertiesByPublisher = async (publisherId) => {
    try {
      setLoading(true);
      const data = await getPropertiesByPublisher(publisherId);
      console.log(`Properties for publisher ${publisherId}:`, data);
      setPropertiesByPublisher(data);
    } catch (error) {
      console.error(`Error fetching properties for publisher ${publisherId}:`, error);
      setPropertiesByPublisher([]);
    } finally {
      setLoading(false);
    }
  };

  const categorizeProperties = (properties) => {
    const categorized = properties.reduce((acc, property) => {
      if (acc[property.type]) {
        acc[property.type].push(property);
      }
      return acc;
    }, {
      Land: [],
      Rent: [],
      Apartment: [],
      HouseForSale: []
    });

    setCategories(categorized);
  };

  const getLand = async () => {
    try {
      setLoading(true);
      const data = await getByCategory('Land');
      return data;
    } catch (error) {
      console.error('Error fetching land properties:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getLease = async () => {
    try {
      setLoading(true);
      const data = await getByCategory('Rent');
      return data;
    } catch (error) {
      console.error('Error fetching lease properties:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getApartment = async () => {
    try {
      setLoading(true);
      const data = await getByCategory('Apartment');
      return data;
    } catch (error) {
      console.error('Error fetching apartment properties:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getHouseForSale = async () => {
    try {
      setLoading(true);
      const data = await getByCategory('HouseForSale');
      return data;
    } catch (error) {
      console.error('Error fetching house-for-sale properties:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllProperties();
  }, []);

  return (
    <PropertiesContext.Provider
      value={{
        properties,
        propertiesByPublisher,
        categories,
        loading,
        fetchPropertiesByPublisher,
        getLand,
        getLease,
        getApartment,
        getHouseForSale
      }}
    >
      {children}
    </PropertiesContext.Provider>
  );
};

const useProperties = () => {
  const context = useContext(PropertiesContext);
  if (!context) {
    throw new Error('useProperties must be used within a PropertiesProvider');
  }
  return context;
};

export { PropertiesProvider, useProperties };
