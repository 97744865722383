import CompareIcon from '@mui/icons-material/Compare';
import HomeIcon from '@mui/icons-material/Home';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import HandshakeIcon from '@mui/icons-material/Handshake';
import * as React from 'react';
import LinkItem from './LinkItem';
import { useLocation } from 'react-router';
import { useState, useEffect } from 'react';

const MenuItem = ({ open }) => {
  const [isHovered, setIsHovered] = useState(false);

  const location = useLocation();

  return (
    <>
      <LinkItem
        itemPath={'/admin/dashboard'}
        itemName={'Dashboard'}
        itemIcon={<HomeIcon />}
        isActive={location.pathname === '/admin/dashboard'}
        open={open}
      />
      <LinkItem
        itemPath={'/admin/property'}
        itemName={'Properties'}
        itemIcon={<ThumbsUpDownIcon />}
        isActive={location.pathname === '/admin/property'}
        open={open}
      />
      <LinkItem
        itemPath={'/admin/users'}
        itemName={'Users'}
        itemIcon={<CompareIcon />}
        isActive={location.pathname === '/admin/users' || location.pathname.startsWith('/admin/users/')}
        open={open}
      />
      {/* <LinkItem
        itemPath={'/admin/permisions'}
        itemName={'Permisions'}
        itemIcon={<AccountBalanceWalletRoundedIcon />}
        isActive={location.pathname === '/admin/permisions'}
        open={open}
      /> */}
       <LinkItem
        itemPath={'/admin/finance'}
        itemName={'Financial records'}
        itemIcon={<AccountBalanceWalletRoundedIcon />}
        isActive={location.pathname === '/admin/finance'}
        open={open}
      />
     
   
    </>
  );
};

export default MenuItem;
