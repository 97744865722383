import apiClient from "../api/apiClient";

export const getAllSubscriptionPlans = async () => {
    try {
      const response = await apiClient.get('/subscription-plan');
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription plans:', error);
      throw error; 
    }
  };
  



 export const createSubscription = async (subscriptionData) => {
  try {
    const { userId, planId } = subscriptionData;
    const response = await apiClient.post('/subscription/create', {
      userId,
      planId
    });

    return response.data; 
  } catch (error) {
    console.error("Error creating subscription:", error);
    throw error;
  }
};

export const checkUserSubscriptionStatus = async (userId) => {
  try {
    const response = await apiClient.get(`/subscription/status/${userId}`);
    console.log(response);
    return response; 

  } catch (error) {
    console.error("Error checking subscription status:", error);
    throw error; 
  }
};