

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getAllSubscriptionPlans } from "../../utils/subscriptionFunc";
import { createSubscription } from "../../utils/subscriptionFunc";
import { getProfile, registerUser } from "../../utils/authFunc";
import { useAuth } from "../../context/authContext";
import { toast } from "react-toastify";

const PaymentPage = () => {
  const { state } = useLocation();
  const loc = useLocation();
  console.log(loc);
  const navigate = useNavigate();

  const [step, setStep] = useState(1); 
  const [paymentMethod, setPaymentMethod] = useState("Mobile Payment");
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(state?.plan || {});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const data = await getAllSubscriptionPlans();
        setPlans(data);
      } catch (error) {
        console.error("Error loading subscription plans:", error);
      }
    };

    fetchPlans();
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    navigate(`/plan/${plan.durationInDays}`, { state: { plan } });
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^(?:\+255|0)\d{9}$/;
    return phoneRegex.test(number);
  };

  const { profile, login } = useAuth();

  const handleSubmitRegistration = async () => {
    if (!firstName || !lastName || !validatePhoneNumber(phoneNumber)) {
      setError("Please fill in all fields correctly.");
      return;
    }

    try {
      console.log("Phone Number", phoneNumber);
      const response = await registerUser(firstName, lastName, phoneNumber);
        console.log(response);
        if(response.sub || response?.data.statusCode == 400){
          console.log("Logiing");
          const res = await login(phoneNumber);
          const profileData = await getProfile();
          console.log("Profile");
          console.log(profileData);
          setUserId(profileData?.sub);  
          setStep(2);
        }

 
    } catch (error) {
      setError("Registration failed. Please try again.");
    }
  };

  const handleSubmitSubscription = async () => {
    console.log(phoneNumber);
    if (!validatePhoneNumber(phoneNumber)) {
      setError("Invalid phone number format. Please use +255xxxxxxxxx or 0xxxxxxxxx.");
      return;
    }

    try {
      await createSubscription({
        userId: profile?.sub, 
        planId: selectedPlan.id,
      });

      navigate(loc.state.from); 
    } catch (error) {
      console.error("Error creating subscription:", error);
      setError("Failed to create subscription. Please try again.");
    }
  };

  return (
    <div className="bg-gray-100 w-full">
      <div className="container mx-auto justify-center p-6 md:p-12">
        <button
          onClick={handleBackClick}
          className="flex items-center text-gray-500 hover:text-gray-900 hover:ml-4 mb-6 transition duration-1200"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 transition duration-1200" />
          Back
        </button>
        {step === 1 && !profile && (
        <div className="flex flex-col text-center items-center my-4">
          <p>Contact Details</p>
          <div className="flex flex-col justify-center align-center my-4">
            <div className="flex justify-center items-center mb-6">
              <label className="mr-2">First Name:</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-1/2 p-2 border rounded-lg"
              />
            </div>
            <div className="flex justify-center items-center mb-6">
              <label className="mr-2">Last Name:</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="w-1/2 p-2 border rounded-lg"
              />
            </div>
            <div className="flex justify-center items-center mb-6">
              <label className="mr-2">Phone Number:</label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-1/2 p-2 border rounded-lg"
              />
            </div>
          </div>
          {error && <p className="text-red-500">{error}</p>}
         { step === 1 && !profile && (
          <button
            onClick={handleSubmitRegistration}
            className="bg-yellow-500 w-1/6 text-white py-1 px-5 rounded-md"
          >
            Submit
          </button>
          )}
        </div>
      )}
      {step === 1 && profile && setStep(2)}
        {step === 2 && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-4">
                {selectedPlan.durationInDays === 1 ? `${selectedPlan.durationInDays} Day` : `${selectedPlan.durationInDays} Days`} Plan
              </h2>
              <p className="mb-6 font-semibold">Choose Payment Method</p>
              <div className="flex justify-between mb-6">
                <button
                  className={`w-1/3 p-4 rounded-lg ${paymentMethod === "Mobile Payment" ? "bg-yellow-500 text-white" : "border"}`}
                  onClick={() => setPaymentMethod("Mobile Payment")}
                >
                  Mobile Payment
                </button>
              </div>

              {paymentMethod === "Mobile Payment" && (
                <>
                  <input
                    type="text"
                    placeholder="Payment Mobile Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="w-full p-2 mb-6 border rounded-lg"
                  />
                  <select className="w-full p-2 mb-6 border rounded-lg">
                    <option value="" disabled selected>
                      Select Mobile Payment Provider
                    </option>
                    <option value="mpesa">M-Pesa</option>
                    <option value="halopesa">Halopesa</option>
                    <option value="airtelmoney">Airtel Money</option>
                    <option value="tpesa">T-pesa</option>
                    <option value="tigopesa">Tigo Pesa</option>
                  </select>
                </>
              )}

              <input type="text" placeholder="Billing Address" className="w-full p-2 mb-6 border rounded-lg" />
              <input type="text" placeholder="Zip Code" className="w-full p-2 mb-6 border rounded-lg" />

              {error && <p className="text-red-500">{error}</p>}

              <div className="flex justify-between">
                <button className="w-1/3 p-3 border rounded-lg hover:bg-gray-100">Cancel</button>
                <button
                  className="w-1/3 bg-yellow-500 p-3 text-white rounded-lg hover:bg-yellow-600"
                  onClick={handleSubmitSubscription}
                >
                  Submit
                </button>
              </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-4">
                {selectedPlan.durationInDays === 1 ? `${selectedPlan.durationInDays} Day` : `${selectedPlan.durationInDays} Days`} Plan
              </h2>
              <div className="border-t border-b py-4">
                <p className="flex justify-between">
                  <span>Subtotal</span>
                  <span>{selectedPlan.price}</span>
                </p>
                <p className="flex justify-between">
                  <span>Discount</span>
                  <span>0%</span>
                </p>
                <p className="flex justify-between font-bold">
                  <span>Total</span>
                  <span>{selectedPlan.price}</span>
                </p>
              </div>
              <p className="font-semibold my-6">Upgrade Plans to Other Plans</p>
              <div className="flex justify-between">
                {plans.map((plan) => (
                  <button
                    key={plan.durationInDays}
                    className={`w-[150px] border p-4 rounded-lg hover:bg-gray-100 ${
                      selectedPlan.durationInDays === plan.durationInDays ? "bg-yellow-500 text-white" : ""
                    }`}
                    onClick={() => handleSelectPlan(plan)}
                  >
                    {plan.durationInDays} {plan.durationInDays > 1 ? "Days" : "Day"}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentPage;
