export const getPictureByName = (mediaData, pictureName, fallback = '/assets/images/image.png') => {
    if (!mediaData || !mediaData.pictures || mediaData.pictures.length === 0) {
      return fallback;
    }
  
    for (let picture of mediaData.pictures) {
      try {
        const parsedNames = picture.name
          .replace(/{|}/g, '') 
          .split(',') 
          .map(name => name.trim().replace(/"/g, '')); 
  
        const index = parsedNames.indexOf(pictureName);
  
        if (index !== -1) {
          return picture.pictures?.[index] || fallback;
        }
      } catch (error) {
        console.error('Error processing picture names:', error);
        continue;
      }
    }
  
    return fallback;
  };

