
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkUserSubscriptionStatus } from '../utils/subscriptionFunc';


const PrivateRoute = ({ element }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { state: { from: location } });
      toast.info("You must be logged in");
    }
  }, [isLoggedIn]);

  return isLoggedIn ? element : <Navigate to="/home" />;
};


const SubscribedRoute = ({ element }) => {
  const { profile } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isActiveSubscription, setIsActiveSubscription] = useState(null); 
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const checkSubscription = async () => {
      if (!isLoggedIn) {
        navigate('/login', { state: { from: location } });
        toast.info("You must be logged in");
        return;
      }

      const subscribed = await checkUserSubscriptionStatus(profile?.sub);
      console.log(subscribed);
      if (subscribed?.data?.isActive) {
        setIsActiveSubscription(true); 
      } else {
        toast.info("You must be subscribed");
        navigate('/subscribe', { state: { from: location } });
      }
    };

    checkSubscription(); 
  }, [profile, location]);

  if (isActiveSubscription === null) {
    return null; 
  }

  return isActiveSubscription ? element : <Navigate to="/subscribe" />;
};

export { PrivateRoute, SubscribedRoute };
