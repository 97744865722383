// UserDetails.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Checkbox, Typography, Grid, Paper, FormControlLabel, Box, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';


const UserDetails = () => {
  const { userId } = useParams(); // Extract userId from the URL

  // Sample user data
  const user = {
    id: userId,
    name: 'John Doe',
    phoneNumber: '123-456-7890',
    permissions: {
      canEdit: true,
      canDelete: false,
      canPost: true,
    },
    properties: [
      { id: 1, type: 'Lease', description: 'Lease for 2 years' },
      { id: 2, type: 'Land', description: 'Land in central city' },
    ],
  };

  const navigate = useNavigate();
  const goBackToUsers = () => {
    navigate('/admin/users');
  };

  const [permissions, setPermissions] = useState(user.permissions);

  const handlePermissionChange = (permKey) => {
    setPermissions({
      ...permissions,
      [permKey]: !permissions[permKey],
    });
  };

  return (
    <Box p={3} mt={12}>
    <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }} onClick={goBackToUsers}>
    <IconButton onClick={goBackToUsers} sx={{ mr: 1, mb: 2 }}> 
        <ArrowBackIcon sx={{ fontSize: 32 }} /> 
    </IconButton>
    <Typography variant="h4" gutterBottom>User Details</Typography>
    </Box>


      {/* User Info */}
      <Card variant="outlined" sx={{ mb: 3, p: 2 }}>
        <Typography variant="h5">User Info</Typography>
        <Typography>ID: {user.id}</Typography>
        <Typography>Name: {user.name}</Typography>
        <Typography>Phone Number: {user.phoneNumber}</Typography>
      </Card>

      {/* User Permissions */}
      <Card variant="outlined" sx={{ mb: 3, p: 2 }}>
        <Typography variant="h5">User Permissions</Typography>
        <FormControlLabel
          control={<Checkbox checked={permissions.canEdit} onChange={() => handlePermissionChange('canEdit')} />}
          label="Can Edit"
        />
        <FormControlLabel
          control={<Checkbox checked={permissions.canDelete} onChange={() => handlePermissionChange('canDelete')} />}
          label="Can Delete"
        />
        <FormControlLabel
          control={<Checkbox checked={permissions.canPost} onChange={() => handlePermissionChange('canPost')} />}
          label="Can Post"
        />
      </Card>

      {/* User Properties */}
      <Card variant="outlined" sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>User Properties</Typography>
        <Grid container spacing={2}>
          {user.properties.map((property) => (
            <Grid item xs={12} md={6} key={property.id}>
              <Paper elevation={2} sx={{ p: 2 }}>
                <Typography variant="h6">{property.type}</Typography>
                <Typography>{property.description}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Box>
  );
};

export default UserDetails;
