
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router';

const Search = ({ placeholder, onSearch, onFilterType, onFilterPrice }) => {
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [selectedType, setSelectedType] = useState('');

  const location = useLocation();

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    onFilterType(e.target.value);
  };

  const handlePriceChange = () => {
    onFilterPrice(minPrice, maxPrice);
  };
  const resetPrice = () => {
    onFilterPrice('', '');
    setMinPrice('');
    setMaxPrice('');
  };

  return (
    <div className="flex flex-col justify-center items-center w-full px-4">
      <div className="flex items-center bg-white rounded-md shadow-md w-full lg:w-[70%] mb-4">
        <input
          type="text"
          placeholder={placeholder}
          className="flex-grow p-2 pl-4 md:pl-12 bg-white outline-none text-sm sm:text-base"
          onChange={(e) => onSearch(e.target.value)}
        />
        <div className="bg-yellow-500 h-[50px] w-[60px] sm:h-[55px] sm:w-[65px] md:h-[60px] md:w-[60px] flex items-center justify-center rounded-md">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-white"
            size="lg"
          />
        </div>
      </div>
      <div className="flex w-full md:w-[40%] items-center justify-center mx-auto space-x-4">
      <div className="flex flex-col">
      {location.pathname === "/home" && (
        <select
          className="bg-white p-2 rounded-md mt-2 shadow-md text-sm sm:text-base"
          value={selectedType}
          onChange={handleTypeChange}
        >
          <option value="">All Types</option>
          <option value="Land">Land</option>
          <option value="Rent">Rent</option>
          <option value="HouseForSale">House for Sale</option>
          <option value="Apartment">Apartment</option>
        </select>
      )}
      </div>

      <div className="flex flex-col w-full md:flex-row space-y-2 space-x-2">
        <div className="flex flex-col md:flex-row space-y-2 space-x-2">
          <input
            type="number"
            placeholder="Min Price"
            className="p-2 rounded-md shadow-md text-sm sm:text-base"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
          />
          <input
            type="number"
            placeholder="Max Price"
            className="p-2 rounded-md shadow-md text-sm sm:text-base"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
          />
        </div>
        <div className='flex justify-between space-x-2'>
        <button
          className="bg-yellow-500 text-white p-2 rounded-md shadow-md"
          onClick={handlePriceChange}
        >
          Apply
        </button>
        <button
          className="bg-yellow-500 text-white p-2 rounded-md shadow-md"
          onClick={resetPrice}
        >
          Reset
        </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Search;
