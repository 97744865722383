

import Layout from './components/Layout.js';
import Home from '../Dashboard/Dashboard.js';

function AdminDashdoard() {
  return (
            <Layout>
               <Home />
            </Layout>
  );
}

export default AdminDashdoard;
