
// import React, { useState } from 'react';
// import Search from '../../components/Search';
// import { useProperties } from '../../context/propertiesContext';
// import ApartmentCard from './components/ApartmentCard';




// const Appartments = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [sortOrder, setSortOrder] = useState('new');

//   const { categories } = useProperties();


//   const filteredProperties = categories?.Apartment?.filter(property => {
//     const address = property?.propertyAddress || {};
//     const locationString = `${address.region?.name || ''} ${address.district?.name || ''} ${address.ward?.name || ''} ${address.street?.name || ''}`.toLowerCase();
//     const title = property?.title?.toLowerCase() || '';  
    
//     return (locationString.includes(searchTerm.toLowerCase()) || title.includes(searchTerm.toLowerCase())) && 
//            (selectedCategory ? property.type === selectedCategory : true);
//   })
//   .sort((a, b) => {
//     if (sortOrder === 'priceLowHigh') {
//       return a.appartmentPrice - b.appartmentPrice;  
//     } else if (sortOrder === 'priceHighLow') {
//       return b.appartmentPrice - a.appartmentPrice;
//     }
//     return 0;
//   });

//   return (
//     <div className="bg-gray-100">
//       <div className="container w-[95%] mx-auto flex flex-col mx-auto">
//         <main className="p-4  items-center">
//           <Search placeholder="Search Properties in Tanzania" onSearch={setSearchTerm} />
          
//           <h1 className="text-2xl font-semibold mt-10 mb-6 text-center">
//             Apartments For Rent
//           </h1>
          
//           <div className="mb-6 flex justify-between w-full space-x-2">
//             {/* <div>
//               <select 
//                 onChange={(e) => setSelectedCategory(e.target.value)} 
//                 value={selectedCategory}
//                 className="border rounded-lg p-2"
//               >
//                 <option value="">All Categories</option>
//                 <option value="Rent">Rent</option>
//                 <option value="For Sale">For Sale</option>
//               </select>
//             </div> */}
//             <div>
//               <select 
//                 onChange={(e) => setSortOrder(e.target.value)} 
//                 value={sortOrder}
//                 className="border rounded-lg p-2"
//               >
//                 <option value="new">Newest</option>
//                 <option value="priceLowHigh">Price: Low to High</option>
//                 <option value="priceHighLow">Price: High to Low</option>
//               </select>
//             </div>
//           </div>
          
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//             {filteredProperties?.map(property => (
//               <ApartmentCard key={property.location} data={property} />
//             ))}
//           </div>
//         </main>
//       </div>
//     </div>
//   );
// };

// export default Appartments;



import React, { useState } from 'react';
import Search from '../../components/Search';
import { useProperties } from '../../context/propertiesContext';
import ApartmentCard from './components/ApartmentCard';
import { getAddressString } from '../../utils/getAddressString'; // Assuming this utility function is available

const Appartments = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [sortOrder, setSortOrder] = useState('new');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const { categories } = useProperties();

  const handleFilterType = (type) => {
    setSelectedCategory(type);
  };

  const handleFilterPrice = (min, max) => {
    setMinPrice(min);
    setMaxPrice(max);
  };

  const getPropertyPrice = (property) => {
    return property.appartmentPrice;
  };

  const filteredProperties = categories?.Apartment?.filter((property) => {
    const matchesSearchTerm = 
      (property.title && typeof property.title === 'string' && property.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (property.propertyAddress && typeof property.propertyAddress === 'object' && getAddressString(property.propertyAddress).toLowerCase().includes(searchTerm.toLowerCase()));
    
    const matchesCategoryFilter = selectedCategory
      ? property.type === selectedCategory
      : true;

    const matchesPriceFilter = 
      (!minPrice || getPropertyPrice(property) >= Number(minPrice)) &&
      (!maxPrice || getPropertyPrice(property) <= Number(maxPrice));

    return matchesSearchTerm && matchesCategoryFilter && matchesPriceFilter;
  }).sort((a, b) => {
    if (sortOrder === 'priceLowHigh') {
      return getPropertyPrice(a) - getPropertyPrice(b);
    } else if (sortOrder === 'priceHighLow') {
      return getPropertyPrice(b) - getPropertyPrice(a);
    }
    return 0;
  });

  return (
    <div className="bg-gray-100">
      <div className="container w-[95%] mx-auto flex flex-col mx-auto">
        <main className="p-4 items-center">
          <Search
            placeholder="Search Apartments in Tanzania"
            onSearch={setSearchTerm}
            onFilterType={handleFilterType}
            onFilterPrice={handleFilterPrice}
          />
          
          <h1 className="text-2xl font-semibold mt-10 mb-6 text-center">
            Apartments For Rent
          </h1>
          
          <div className="mb-6 flex justify-between w-full space-x-2">
            <div>
              <select 
                onChange={(e) => setSortOrder(e.target.value)} 
                value={sortOrder}
                className="border rounded-lg p-2"
              >
                <option value="new">Newest</option>
                <option value="priceLowHigh">Price: Low to High</option>
                <option value="priceHighLow">Price: High to Low</option>
              </select>
            </div>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {filteredProperties?.map((property) => (
              <ApartmentCard key={property.location} data={property} />
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Appartments;
