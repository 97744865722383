
export const getAddressString = (propertyAddress) => {
    const {
      apartmentNumber = '',
      houseNumber = '',
      plotNumber = '',
      street = {},
      ward = {},
      district = {},
      region = {}
    } = propertyAddress;
  
    let addressString = '';
  
    if (apartmentNumber) {
      addressString += `Apartment ${apartmentNumber}, `;
    }
    if (houseNumber) {
      addressString += `House No. ${houseNumber}, `;
    }
    if (plotNumber) {
      addressString += `Plot No. ${plotNumber}, `;
    }
  
    if (street.name) {
      addressString += `Street ${street.name}, `;
    }
    if (ward.name) {
      addressString += `Ward ${ward.name}, `;
    }
    if (district.name) {
      addressString += `District ${district.name}, `;
    }
    if (region.name) {
      addressString += `Region ${region.name}`;
    }
  
    return addressString.trim().replace(/,\s*$/, '');
  };
