import axios from 'axios';


const token = localStorage.getItem('prop-token');

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://staging.api.direct.co.tz/api',
  timeout: parseInt(process.env.REACT_APP_API_TIMEOUT, 10) || 10000, 
  headers: {
    'Content-Type': 'application/json',
    'Authorization': token ? `Bearer ${token}` : '', 
  },
});

export default apiClient;
