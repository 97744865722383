
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Banner = () => {
  return (
    <div className="bg-gray-100 p-4 md:p-8 lg:p-8 rounded-lg flex flex-col md:flex-row lg:flex-row justify-between items-center my-2 md:-my-6 w-[100%] md:w-[87%] mx-auto">
      <div className="flex-1 md:mr-7 lg:mr-7 mb-8 md:mb-0 lg:mb-0 text-center md:text-left lg:text-left">
        <h2 className="text-3xl md:text-3.5xl lg:text-5xl font-bold text-gray-800 pb-4">
          Experience Seamless Property Hunting: Just Pay for Viewing
        </h2>
        <p className="text-gray-600 mt-4 text-lg md:text-xl lg:text-xl lg:text-2xl">
          Say goodbye to complicated processes. Pay for property viewings and enjoy seamless renting or buying options.
        </p>
        <button className="bg-yellow-500 text-black mt-8 md:my-16 lg:my-16 py-3 px-6 md:px-8 lg:px-8 rounded-md flex items-center justify-center mx-auto md:mx-0 transition-all duration-400 ease-in-out hover:space-x-6">
      <span>EXPLORE PROPERTIES BELOW</span>
      {/* <FontAwesomeIcon icon={faArrowRight} className="ml-2 transition-all duration-300 ease-in-out hover:ml-4" /> */}
    </button>
      </div>
      <div className="flex-1 flex justify-center">
        <img 
          src={process.env.PUBLIC_URL + '/assets/images/sponsored.png'} 
          alt="Property Banner" 
          className="w-full h-auto rounded-lg shadow-md md:mt-6 lg:mt-6 md:w-[720px] lg:w-[740px] md:h-[354px] lg:h-[370px]" 
        />
      </div>
    </div>
  );
};

export default Banner;
